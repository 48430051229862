<template>
<v-container fluid>
  <v-row class="justify-center">
    <v-col>
      <v-card elevation="0" min-width="500" height="400">
        <div>
          <div class="donut-chart">
            <DonutChart :data="points1" :palette="palette1" />
          </div>
          <div class="chart-total">
            {{ total1 }}<span class="text-h5">人</span>
            <div class="grey--text">总人数</div>
          </div>
          <div class="chart-title">{{ title1 }}</div>
          <div class="chart-subtitle mt-4">
            <v-icon class="mx-2" x-small color="yellow">mdi-lightbulb-on</v-icon><span>{{ subtitle1}}</span>
          </div>
          <div class="chart-legend">
            <div @click="selectLegend1(i)" v-for="(btn, i) in points1" class="chart-legend-item">
              <div class="justify-start">{{ btn.name }}</div>
              <div>{{ btn.y }}人</div>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col>
      <v-card elevation="0" min-width="500" height="400">
        <div>
          <div class="donut-chart">
            <DonutChart :data="points2" :palette="['SlateGrey', 'green', 'blue', 'orange']" />
            <div class="chart-total">
              {{ total2 }}<span class="text-h5">个</span>
              <div class="grey--text">总人数</div>
            </div>
          </div>
          <div class="chart-title">{{ title2 }}</div>
          <div class="chart-subtitle mt-4">
            <v-icon class="mx-2" x-small color="yellow">mdi-lightbulb-on</v-icon><span>{{ subtitle2}}</span>
          </div>
          <div class="chart-legend">
            <div @click="selectLegend2(i)" v-for="(btn, i) in points2" class="chart-legend-item">
              <div class="justify-start">{{ btn.name }}</div>
              <div>{{ btn.y }}个</div>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
export default {
  name: 'RateCircleChart',
  components: {
    DonutChart: () => import('@/components/okrdashboard/DonutChart.vue'),
  },
  props: ['data1', 'data2'],
  computed: {
    points1: function () {
      return [{
          name: '未设置OKR',
          y: this.data1[1]
        },
        {
          name: '1-5个OKR',
          y: this.data1[2]
        },
        {
          name: '5个以上OKR',
          y: this.data1[3]
        },
      ]
    },
    total1: function () {
      let temp = 0;
      this.points1.map(point => {
        temp += point.y;
      })
      return temp;
    },
    total2: function () {
      let temp = 0;
      this.points2.map(point => {
        temp += point.y;
      })
      return temp;
    },
    points2: function () {
      return [{
          name: '未设置KR',
          y: this.data2[1]
        },
        {
          name: '1-2个KR',
          y: this.data2[2]
        },
        {
          name: '3-5个KR',
          y: this.data2[3]
        },
        {
          name: '5个以上KR',
          y: this.data2[4]
        }
      ]
    }
  },
  data() {
    return {
      title1: '每个人负责的目标 O 数量分布',
      title2: '目标 O 下的 KR 数量分布',
      subtitle1: '单周期内个人负责 OKR 数量1-3个最佳，最多不超过5个',
      subtitle2: '每个OKR中KR数量以3-5个为最佳',
      palette1: ['SlateGrey', 'green', 'orange']
    }
  },
  methods: {
    selectLegend1(index) {
      this.$emit('selectLegend1', index);
    },
    selectLegend2(index) {
      this.$emit('selectLegend2', index);
    }
  }
}
</script>

<style>
.chart-legend-item {
  border: 1px solid grey;
  width: 100px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

.chart-legend-item:hover {
  border: 1px solid green;
}

.donut-chart {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.chart-total {
  position: absolute;
  top: 220px;
  left: 50%;
  transform: translate(-50%);
  font-size: 40px;
  z-index: 3;
  text-align: center;
  max-width: 400px;
  min-width: 300px;
}

.chart-title {
  position: absolute;
  z-index: 2;
  left: 20px;
  top: 20px;
  font-size: 18px;
}

.chart-subtitle {
  position: absolute;
  left: 10px;
  top: 50px;
  color: grey;
}

.chart-legend {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 4;
}
</style>
